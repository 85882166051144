

























































































































import { Reports, User, Global, DeviceStreams, Props, Groups, ProjectStreams } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import API , { Types } from '@/modules/API';
import { Prop, Watch } from 'vue-property-decorator';
import PowerConsumptionDaily from '@/pages/reports/components/PowerConsumptionDaily.vue';
import moment from 'moment-timezone';
import BarChart from '@/components/BarChart.vue';
import DatePicker from '@/pages/reports/components/DatePicker.vue';
import PowerConsumptionInfo from '@/pages/reports/components/PowerConsumptionInfo.vue';

@Component({ 
  components: {
    PowerConsumptionDaily,
    PowerConsumptionInfo,
    BarChart,
    DatePicker
  }
})

export default class PowerConsumptionReport extends Vue {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Reports.State('reportsList') devices;
  @Reports.State('devicesCount') devicesCount;
  @Reports.Getter('commissionedFixtures_map') commissionedFixtures_map;
  @User.State('project') project;
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;
  @Groups.State('list') groups;
  @ProjectStreams.Action('geAbsoluteStreamHistory') geAbsoluteStreamHistory;
  @ProjectStreams.State('absoluteStreamHistory') absoluteStreamHistory;

  @Prop() external_periodSelected;
  @Prop() external_selectedTab;
  @Prop() cabinet_id;

  reportData = [];
  reversedData = [];
  periodSelected: string = '';
  period = {start: 0, end: 0};
  loadingReport = false;
  loading = false;
  noData = false;
  counter = 0;
  cabinetsList = [];
  selectedValue = '';
  groupsList = [];
  groupsName = [];
  groups_map = new Map();
  cache_power_consumption = new Map();
  NO_DATA_MESSAGE = 'No Data Available';
  ERROR_MESSAGE = 'Error while loading histroy';
  message = '';
  error = false;
  set_default = true;
  start_date = null;
  end_date = null;
  cabinet_ids_map = new Map();
  custom_map = new Map();
  range_by_year_map = new Map();
  unitsJson = {
    hours: 'asHours',
    months: 'asMonths'
  };

  selectedTab = 'cabinet';
  start_time = null;
  end_time = null;
  dates = {day_start: null, day_end: null, day_stream: null, month_start: null, month_end: null, month_stream: null, year_start: null, year_end: null, year_stream: null, custom_start: null, custom_end: null, custom_stream: null};
  total_power_consumption = 0;
  chartSeries = [];
  chartCategories = [['Tondo LED', 'Consumption', `[kWh]`], ['Past Consumption', 'For HPS, No Dimming', `[kWh]`], ['Carbon', 'Emission', `[Kg CO2]`], ['Carbon Emission', 'Saving', `[Kg CO2]`]];
  chartColors = ['#812E81', '#E57373', '#FFB74D', '#AED581'];

  mounted() {
    this.setPageTitle('Controller Power Consumption Report');
    this.generatePageData();
  }

  @Watch('devices')
  @Watch('devicesCount')
  generatePageData(){
    if (this.devices.length !== this.devicesCount) return;
    this.loading = true;
    this.initializeGeneralData();
    this.updateDates();
    this.setCabinetList();
    if (!this.external_selectedTab) this.setGroupList();
    else if (this.external_selectedTab === 'cabinet'){
      this.selectedTab = 'cabinet';
      this.periodSelected = this.external_periodSelected;
      this.selectedValue = this.cabinet_id;
      this.cabinet_ids_map.has(this.cabinet_id) ? this.generateReport() : this.noData = true;
    }
    this.loading = false;
  }

  get periodsList(){
    return [
      { text: this.$t('Last Day'), value: 'day' },
      { text: this.$t('Last Month'), value: 'month' },
      { text: this.$t('Last Year'), value: 'year' },
      { text: this.$t('Custom'), value: 'custom' }
    ];
  }

  initializeGeneralData(){
    this.reportData = [];
    this.total_power_consumption = 0;

    this.start_time = {
      hour: 0, 
      minute: 0, 
      second: 0, 
      millisecond: 0
    };

    this.end_time = {
      hour: 23, 
      minute: 59, 
      second: 59, 
      millisecond: 59
    };
  }

  setCabinetList(){
    let cabinet_ids = this.fixture_meta_device_options.cabinet_id;
    cabinet_ids = cabinet_ids && cabinet_ids.options || [];
    cabinet_ids = ['all'].concat(cabinet_ids);
    cabinet_ids.forEach((cabinet_id) => this.cabinet_ids_map.set(cabinet_id, []));
    [...this.commissionedFixtures_map.entries()].forEach(([device_id, device]) => {
      const meta_device = device['meta.device'];
      const cabinet_id = meta_device.cabinet_id || '';
      if (cabinet_id) {
        this.cabinet_ids_map.has(cabinet_id) 
          ? this.cabinet_ids_map.get(cabinet_id).push({id: device_id, ...meta_device}) 
          : this.cabinet_ids_map.set(cabinet_id, [{id: device_id, ...meta_device}]); 
      }
      this.cabinet_ids_map.get('all').push({id: device_id, ...meta_device});
    });
    this.cabinetsList = [...this.cabinet_ids_map.keys()].filter((cabinet_id) => cabinet_id !== 'all').map((cabinet_id) => ({text: cabinet_id, value: cabinet_id}));
    this.cabinetsList.sort((c1, c2) => c1.text.localeCompare(c2.text, undefined, {numeric: true, sensitivity: 'base'}));
    this.cabinetsList = [{text: 'All', value: 'all'}].concat(this.cabinetsList);
  }

  get items(){
    return this.selectedTab === 'group'
      ? this.groupsList.map((group) => ({text: this.$t(group.text), value: group.value}))
      : this.cabinetsList.map((cabinet) => ({text: this.$t(cabinet.text), value: cabinet.value}));
  }

  get selectLabel(){
    return this.selectedTab === 'group'
      ? 'Select Group'
      : 'Select Cabinet ID';
  }

  @Watch('start_date')
  @Watch('end_date')
  updateStartDate(){
    this.reportData = [];
  }

  setDefaultDates(){
    this.set_default = true;
    this.setStartDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.set_default = false;
  }

  setStartDate(start){
    this.start_date = start;
  }

  setEndDate(end){
    this.end_date = end;
  }

  setGroupList(){
    this.groupsList = this.groups.map((group) => ({ text: group.name, value: group.id }));
    this.groupsList.sort((g1, g2) => g1.text.localeCompare(g2.text, undefined, {numeric: true, sensitivity: 'base'}));
    this.groupsList = [{ text: 'All', value: 'all' }].concat(this.groupsList);
  }

  updateDates(){
    const today_timestamp = moment().unix() * 1000;
    const yesterday_timestamp = moment(today_timestamp).subtract(1, 'days').valueOf();
    const month_earlier_timestamp = moment(today_timestamp).subtract(1, 'months').set({date: 1}).valueOf();
    const year_earlier_timestamp = moment(today_timestamp).subtract(1, 'years').set({date: 1, month: 0}).valueOf();
    
    this.dates.day_start = moment(yesterday_timestamp).set(this.start_time).valueOf();
    this.dates.day_end = moment(yesterday_timestamp).set(this.end_time).valueOf();
    this.dates.day_stream = 'power_consumption_daily';
    this.dates.month_start = moment(month_earlier_timestamp).set(this.start_time).valueOf();
    this.dates.month_end = moment(month_earlier_timestamp).set(this.end_time).valueOf();
    this.dates.month_stream = 'power_consumption_monthly';
    this.dates.year_start =  moment(year_earlier_timestamp).set(this.start_time).valueOf();
    this.dates.year_end = moment(year_earlier_timestamp).set(this.end_time).valueOf();
    this.dates.year_stream = 'power_consumption_yearly';
    this.dates.custom_start = 0;
    this.dates.custom_end = 0;
    this.dates.custom_stream = 'power_consumption_daily';
  }

  async fetchPowerConsumption(stream_name){
    if (this.periodSelected === 'custom' || (this.periodSelected !== 'custom' && this.checkNotCalculated())){
      const parameters = {
        stream_name,
        aggregators: [],
        start: this.period.start,
        end: this.period.end
      };

      if (this.periodSelected === 'custom'){
        parameters.aggregators.push({ 
          name: 'sum'
        });
      }

      await this.geAbsoluteStreamHistory(parameters);
      this.setStreamData();
    }
  }

  checkNotCalculated(){
    return !this.cache_power_consumption.size || ![...this.cache_power_consumption.values()][0][this.periodSelected];
  }

  setStreamData(){
    if (!this.absoluteStreamHistory.length || (this.absoluteStreamHistory.length === 1 && !this.absoluteStreamHistory[0].tags.device_id)){
      this.noData = true;
      return;
    }

    if (this.periodSelected === 'custom'){
      this.custom_map = new Map();
      this.absoluteStreamHistory.forEach((device) => {
        const device_id = device.tags.device_id[0];
        const power_consumption = device.values 
          && device.values.length 
          && (device.values.reduce((acc, curr) => acc + curr[1], 0) / 1000).toFixed(3);
        this.custom_map.set(device_id, +power_consumption);
      });
    }else {
      this.absoluteStreamHistory.forEach((device) => {
        const device_id = device.tags.device_id[0];
        const power_consumption = device.values && device.values.length && (device.values[0][1] / 1000).toFixed(3);
        this.cache_power_consumption.set(device_id, {[this.periodSelected]: +power_consumption});
      });
    }
  }

  @Watch('periodSelected')
  setData(){
    this.reportData = [];
    this.periodSelected === 'custom'
      ? this.setDefaultDates()
      : this.clearCustomDates();
  }

  @Watch('selectedValue')
  async updateGroupDevices(){
    this.reportData = [];
    if (this.selectedTab === 'group'){
      if (this.selectedValue === 'all' && !this.groups_map.has(this.selectedValue)){
        this.groups_map.set(this.selectedValue, this.cabinet_ids_map.get('all'));
      }else if (this.selectedValue && !this.groups_map.has(this.selectedValue)){
        this.groups_map.set(this.selectedValue, await this.fetchGroupDevices());
      }
    }
  }

  @Watch('selectedTab')
  clearData(){
    if (!this.external_selectedTab){
      this.periodSelected = '';
      this.reportData = [];
      this.selectedValue = '';
      this.noData = false;
    }
  }
   
  async fetchGroupDevices(){
    const groupData = await API.get(Types.PROJECTS, `${this.project.id}/groups/${this.selectedValue}`);
    return groupData.devices.map((device_id) => ({id: device_id, ...this.commissionedFixtures_map.get(device_id)['meta.device']}));
  }

  setPeriod(){
    if (this.periodSelected === 'custom'){
      if (this.start_date > this.end_date) {
        const temp = this.start_date;
        this.start_date = this.end_date;
        this.end_date = temp;
      }
      this.dates.custom_start = moment(this.start_date).set(this.start_time).valueOf();
      this.dates.custom_end = moment(this.end_date).set(this.end_time).valueOf();
    }
    
    this.period = {
      start: this.dates[`${this.periodSelected}_start`],
      end: this.dates[`${this.periodSelected}_end`]
    }; 
  }

  async generateReport() {
    this.loadingReport = true;
    this.reportData = [];
    this.total_power_consumption = 0;
    this.noData = false;
    this.setPeriod();
    await this.fetchPowerConsumption(this.dates[`${this.periodSelected}_stream`]);
    if (!this.noData) 
      this.setReportData(); 
    else{
      this.clearCustomDates();
      this.loadingReport = false;
      return;
    }

    if (!this.reportData.length) {
      this.noData = true;
      this.message = this.error ? this.ERROR_MESSAGE : this.NO_DATA_MESSAGE;
    }

    this.loadingReport = false;
  }

  setReportData(){
    this.reportData = this.periodSelected === 'custom' ? this.getCustomReportData() : this.getAbsoluteReportData();
    const total = this.getTotalPowerConsumption();
    this.total_power_consumption = total ? total.toFixed(3) : total;
    const co2_no_led = +(this.total_power_consumption * 2.77).toFixed(3);
    const co2_emmission = +(this.total_power_consumption * 0.65).toFixed(3); // according to IEC
    const co2_saving = (+(+co2_no_led - +this.total_power_consumption) * 0.65).toFixed(3);
  
    this.chartSeries = [{data: [this.total_power_consumption, co2_no_led, co2_emmission, co2_saving]}];
  }

  getCustomReportData(){
    return this.selectedTab === 'group'
      ? this.groups_map.get(this.selectedValue).map((device) => 
        ({id: device.id, cabinet_id: device.cabinet_id, pole_number: device.pole_number, circuit_number: device.circuit_number, power_consumption: this.getCustomValue(device.id)}))
      : this.cabinet_ids_map.get(this.selectedValue).map((device) => 
        ({id: device.id, cabinet_id: device.cabinet_id, pole_number: device.pole_number, circuit_number: device.circuit_number, power_consumption: this.getCustomValue(device.id)}));
  }

  getAbsoluteReportData(){
    const devices_map = this.selectedTab === 'group' ? 'groups_map' : 'cabinet_ids_map';
    return this[devices_map].get(this.selectedValue).map((device) => (
      {
        id: device.id,
        cabinet_id: device.cabinet_id,
        pole_number: device.pole_number,
        circuit_number: device.circuit_number,
        power_consumption: this.getAbsoluteValue(device.id)
      }
    ));
  }

  getCustomValue(device_id){
    return this.custom_map.has(device_id) ? this.custom_map.get(device_id) : '';
  }

  getAbsoluteValue(device_id){
    return this.cache_power_consumption.has(device_id) ? this.cache_power_consumption.get(device_id)[this.periodSelected] : '';
  }

  getTotalPowerConsumption(){
    return this.reportData.reduce((acc, curr) => {
      return typeof curr.power_consumption === 'number' 
        ? acc + curr.power_consumption
        : acc;
    }, 0);
  }

  clearCustomDates() {
    this.setDefaultDates();
    this.dates.custom_start = 0;
    this.dates.custom_end = 0;
  }
}
