



























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class PowerConsumptionDaily extends Vue {
  @Prop() reportData;
  @Prop() total_power_consumption;
  @Prop() selectedTab;

  @Global.State('lang') lang;

  header_color = '#EC1163';
  rows_color = ['#F8CCD3', '#FBE7EA'];
  tableData = [];
  loading = true;
  search = '';

  get headers() {
    return [
      {
        text: 'Device ID',
        sortable: true,
        class: [
          this.lang === 'en' ? 'text-xs-left' : 'text-xs-right',
          'title',
          'font-weight-bold'
        ],
        value: 'id'
      },
      {
        text: 'Cabinet ID',
        sortable: true,
        class: [
          this.lang === 'en' ? 'text-xs-left' : 'text-xs-right',
          'title',
          'font-weight-bold'
        ],
        value: 'cabinet_id'
      },
      {
        text: 'Pole Number',
        sortable: true,
        class: [
          this.lang === 'en' ? 'text-xs-left' : 'text-xs-right',
          'title',
          'font-weight-bold'
        ],
        value: 'pole_number'
      },
      {
        text: 'Circuit Number',
        sortable: true,
        class: [
          this.lang === 'en' ? 'text-xs-left' : 'text-xs-right',
          'title',
          'font-weight-bold'
        ],
        value: 'circuit_number'
      },
      {
        text: 'Power Consumption [kWh]',
        sortable: true,
        class: [
          this.lang === 'en' ? 'text-xs-left' : 'text-xs-right',
          'title',
          'font-weight-bold'
        ],
        value: 'power_consumption'
      }
    ];
  }

  customGroupFilter(items, search, filter) {
    search = search.toString().toLowerCase();
    return items.filter((row) => filter(row.cabinet_id, search));
  }

  customCabinetFilter(items, search, filter) {
    search = search.toString().toLowerCase();
    return items.filter((row) => filter(row.pole_number, search) || filter(row.circuit_number, search));
  }
}
